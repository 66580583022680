@font-face {
  font-family: 'Thunderstorm';
  src: url('./assets/Thunderstorm.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App {
  height: auto;
  font-family: 'Times New Roman', Times, serif;
  background-color: #000;
  background-image: url("./assets/heroBg.jpg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: hidden;
}

.App h1{
  font-family: 'Thunderstorm';
}

.SeenSec{
  visibility: hidden
}