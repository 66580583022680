@font-face {
    font-family: 'Thunderstorm';
    src: url('../assets/Thunderstorm.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.NavBar{
    height: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background-color: #000;
    position: fixed;
    z-index: 100;
    transition: 0.5s ease;
    user-select: none;
}

.MenuIconCon{
    width: 240px;
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.NavColor{
    background-color: #fff;
}

.NavBoard{
    height: 70px;
    width: 165px;
    background-image: url("../assets/board.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    cursor: pointer;
}

.NavCC{
    height: 100px;
    width: 165px;
    background-image: url("../assets/ccBlack.jpg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    transition: 0.5s ease;
    cursor: pointer;
}

.NavCC1{
    background-image: url("../assets/ccWhite.jpg");
}

.NftArt{
    height: 50px;
    width: 200px;
    background-image: url("../assets/Black\ logo\ 600px.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    transition: 0.5s ease;
    cursor: pointer;
}

.NftArt1{
    height: 50px;
    width: 200px;
    margin-left: 20px;
    background-image: url("../assets/White\ logo\ 600px.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    transition: 0.5s ease;
    cursor: pointer;
}

.NftArt2{
    height: 50px;
    width: 200px;
    background-image: url("../assets/White\ logo\ 600px.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    transition: 0.5s ease;
    cursor: pointer;
}

.FootCal1{
    height: 100px;
    width: 100px;
    margin-top: 20px;
    background-image: url("../assets/calWhite.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    transition: 0.5s ease;
    cursor: pointer;
}

.NavCal{
    height: 100px;
    width: 105px;
    background-image: url("../assets/calWhite.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    transition: 0.5s ease;
    cursor: pointer;
}

.FootCal{
    height: 60px;
    width: 60px;
    margin-right: 20px;
    background-image: url("../assets/calWhite.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    transition: 0.5s ease;
    cursor: pointer;
}

.NavCalLeft{
    display: flex;
}

.NavCalRight{
    display: none;
}

.NavBarMenu{
    width: 85%;
    margin: 0;
    height: 70px;
    padding-left: 7.5%;
    padding-right: 7.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NavBarMenu1{
    width: 85%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
}

.NavBarMenu1 li{
    text-align: center;
    font-weight: 600;
}

.NavBar li{
    margin: 0;
    position: relative;
    list-style: none;
    font-weight: 600;
    cursor: pointer;
}

.ArrowIcon{
    position: absolute;
    left: 50%;
    bottom: -35px;
    transform: translate(-50%, -50%);
}

.Logo{
    height: 100px;
    width: 200px;
    background-image: url("../assets/logowhite.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.5s ease;
    cursor: pointer;
}

.Logo1{
    background-image: url("../assets/logoblack.png");
}

.SocialCon{
    width: 330px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SocialCon1{
    width: 300px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NavBar img{
    height: 26px;
    width: 26px;
    cursor: pointer;
}

.NavBar span{
    width: 85%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MenuText{
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    transition: 0.5s ease;
}

.MenuTextWhite{
    color: #fff;
}

.MobileMenuCon{
    width: 70%;
    height: 100%;
    position: fixed;
    top: 0px;
    right: -70%;
    background-color: #bf1b25;
    transition: 0.5s ease;
}

.MenuShow{
    right: 0px;
}

.MenuClose{
    position: absolute;
    right: 25px;
    top: 30px
}

.Hero{
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/heroBg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
}

.Hero video{
    height: 100vh;
    width: 100%;
    object-fit: cover;
    user-select: none;
}

.Story{
    height: auto;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
}

.Story img{
    width: 100%;
    margin-top: 20px;
}

.Story h1{
    color: #fff;
    margin: 0;
    text-align: center;
    font-size: 96px;
    font-weight: 400;
    margin-top: 160px;
}

.Story p{
    color: #fff;
    margin: 0;
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
}

.Story span{
    width: 100%;
    display: flex;
    margin-top: 20px;
    /* margin-top: 150px; */
    position: relative;
}

.StoryLeft{
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: flex-start;
}

.StoryRight{
    display: flex;
    flex-direction: column;
    width: 20%;
    /* position: absolute;
    right: -180px;
    top: -30px; */
    align-items: flex-end;
}

.Mint{
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* padding: 40px 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Mint span{
    width: 85%;
    align-items: center;
    display: flex;
    /* margin-top: 160px; */
}

.Mint h1{
    color: #fff;
    margin: 0;
    text-align: left;
    font-size: 96px;
    font-weight: 400;
}

.Mint h2{
    color: #fff;
    margin: 0;
    text-align: justify;
    font-size: 24px;
    margin-top: 20px;
    font-weight: 400;
}

.Mint h3{
    color: #fff;
    margin: 0;
    text-align: justify;
    font-size: 24px;
    margin-top: 20px;
    font-weight: 400;
}

.Mint p{
    color: #fff;
    margin: 0;
    text-align: justify;
    font-size: 24px;
    margin-top: 20px;
}

.MintLeft{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
}

.MintRight{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
}

.Mint img{
    width: 50%;
}

.Mint button{
    border: none;
    color: #fff;
    font-size: 25px;
    border-radius: 10px;
    background-color: #bf1b25;
    padding: 10px 25px;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 25px;
    cursor: pointer;
}

.Paper{
    height: auto;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Paper img{
    width: 100%;
}

.Paper h1{
    color: #fff;
    margin: 0;
    text-align: left;
    font-size: 96px;
    font-weight: 400;
    margin-top: 160px;
}

.Paper a{
    color: #fff;
}

.Paper p{
    color: #fff;
    margin: 0;
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
}

.Paper span{
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.PaperLeft{
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: flex-start;
}

.PaperRight{
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: flex-start;
}

.RoadMap{
    color: #fff;
    height: auto;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RoadMap h1{
    color: #fff;
    margin: 0;
    margin-top: 160px;
    width: 85%;
    text-align: center;
    font-size: 96px;
    font-weight: 400;
}

.RoadMap p{
    color: #fff;
    margin: 0;
    width: 85%;
    text-align: center;
    font-size: 24px;
    margin-top: 10px;
    font-weight: 400;
    margin-bottom: 50px;
}

.RoadMap h3{
    color: #000;
    margin: 0;
    font-size: 25px;
    font-weight: 400;
}

.RoadMap h4{
    color: #000;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.RoadMapLeft{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.RoadMapRight{
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.RoadMap img{
    width: 70%; 
}

.MapCon{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.MapCon h2{
    font-size: 25px;
    color: #fff;
    margin: 0;
    font-weight: 500;
}

.MapCon h3{
    font-size: 18px;
    color: #fff;
    margin: 0;
    font-weight: 400;
}

.MapCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5%;
}

.MapLeft{
    width: 50px;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-bottom: 25px;
}

.MapRight{
    width: 75%;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 25px;
}

.MapInd{
    min-height: 12px;
    min-width: 12px;
    background-color: #fff;
    border-radius: 10px;
    border: 3px #bf1b25 solid;
}

.MapLine{
    width: 3px;
    background-color: #bf1b25;
    height: 100%;
}

.Team{
    height: auto;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeamCon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeamCon1{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Team h1{
    color: #fff;
    margin: 0;
    width: 85%;
    text-align: center;
    font-size: 96px;
    margin-top: 160px;
    font-weight: 400;
}

.Team p{
    color: #fff;
    margin: 0;
    width: 85%;
    text-align: center;
    font-size: 24px;
    margin-top: 10px;
    font-weight: 400;
}

.TeamText{
    text-align: center;
}

.Team h2{
    margin: 0;
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-weight: 400;
}

.Team span{
    width: 85%;
    display: flex;
    align-items: center;
    margin-top: 50px;
}

.TeamLeft{
    display: flex;
    width: 50%;
}

.TeamRight{
    display: flex;
    width: 50%;
}

.TeamLeft1{
    display: flex;
    width: 50%;
}

.TeamRight1{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}


.Team img{
    width: 70%;
    /* border-radius: 50%; */
}

.Marketing{
    height: auto;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Marketing h1{
    color: #fff;
    margin: 0;
    width: 85%;
    text-align: center;
    font-size: 96px;
    font-weight: 400;
}

.Marketing span{
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Marketing video{
    width:42.5%;
    margin-top: 50px;
}

.Marketing img{
    width: 42.5%;
    margin-top: 50px;
}

.Footer{
    height: 60px;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    bottom: 0px;
    justify-content: center;
    background-color: #000;
    transition: 00.5s ease;
}

.FootColor{
    background-color: #fff;
}

.Club{
    height: 50vh;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Club h1{
    color: #fff;
    margin: 0;
    width: 85%;
    text-align: center;
    font-weight: 400;
    font-size: 96px;
}

.Club h2{
    color: #fff;
    margin: 0;
    width: 85%;
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
}

.Gallery{
    min-height: 80vh;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Gallery h1{
    color: #fff;
    margin: 0;
    width: 85%;
    text-align: center;
    font-weight: 400;
    font-size: 96px;
}

.Gallery h2{
    color: #fff;
    margin: 0;
    width: 85%;
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
}

.Gallery p{
    color: #fff;
    margin: 0;
    width: 85%;
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
    font-weight: 400;
}

.Gallery span{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.Seen{
    min-height: 80vh;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Seen h1{
    color: #fff;
    margin: 0;
    width: 85%;
    text-align: center;
    font-weight: 400;
    font-size: 96px;
}

.Seen h2{
    color: #fff;
    margin: 0;
    width: 85%;
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
}

.Seen p{
    color: #fff;
    margin: 0;
    width: 85%;
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
    font-weight: 400;
}

.Seen span{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.Reveal{
    height: 50vh;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Reveal h1{
    color: #fff;
    margin: 0;
    width: 85%;
    text-align: center;
    font-weight: 400;
    font-size: 96px;
}

.Reveal h2{
    color: #fff;
    margin: 0;
    width: 85%;
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
}

@media only screen and (max-width: 1050px) {
    .NavBar span{
        width: 90%;
    }
    .Mint span{
        width: 90%;
    }
    .RoadMap  h1{
        color: #fff;
        width: 90%;
    }
    .RoadMap p{
        width: 90%;
    }
    .Team h1{
        color: #fff;
        width: 90%;
    }
    .Team p{
        width: 90%;
    }
    .Team span{
        width: 90%;
    }
    .Marketing h1{
        color: #fff;
        width: 90%;
    }
    .Marketing span{
        width: 90%;
    }
    .Club h1{
        color: #fff;
        width: 90%;
    }
    .Gallery h1{
        color: #fff;
        width: 90%;
    }
    .Gallery h2{
        width: 90%;
    }

    .Seen h1{
        color: #fff;
        width: 90%;
    }
    .Seen h2{
        width: 90%;
    }
    .Reveal h1{
        color: #fff;
        width: 90%;
    }
    .MapCenter{
        width: 30px;
    }
}

@media only screen and (max-width: 1200px) {
    .NavBarMenu{
        height: 0px;
        display: none;
        overflow: hidden;
    }
    .NavBar{
        height: 100px;
    }
    .SocialCon{
        display: none;
    }
    .MenuIconCon{
        width: 330px;
        display: flex;
    }
}

@media only screen and (max-width: 950px) {
    .Marketing video{
        width: 50%;
    }
    .Marketing img{
        width: 50%;
    }
    .Story img{
        width: 80%;
    }
    .Mint img{
        width: 80%;
    }
    .Paper img{
        width: 80%;
    }
    .RoadMap img{
        width: 80%;
    }
    .Team img{
        width: 80%;
    }
}

@media only screen and (max-width: 910px) {
.NavCalLeft{
    display: none;
}

.NavCalRight{
    display: flex;
}
}

@media only screen and (max-width: 700px) {
    .Marketing video{
        width: 100%;
    }
    .Marketing img{
        width: 100%;
    }
    .Story img{
        width: 90%;
    }
    .Mint img{
        width: 90%;
    }
    .Paper img{
        width: 90%;
    }
    .RoadMap img{
        width: 90%;
    }
    .Team img{
        width: 90%;
    }
    .NavBoard{
        width: 80px;
        height: 80px;
    }
    .NavCC{
        width: 80px;
        height: 80px;
    }
    .NavCal{
        width: 80px;
        height: 80px;
    }
    .MenuIconCon{
        width: 160px;
    }
    .Logo{
        width: 150px;
    }
    .Story h1{
        font-size: 35px;
    }
    .Paper h1{
        font-size: 35px;
    }
    .Mint h1{
        font-size: 35px;
    }
    .RoadMap h1{
        font-size: 35px;
    }
    .RoadMap h4{
        font-size: 18px;
    }
    .Reveal h1{
        font-size: 35px;
    }
    .Club h1{
        font-size: 35px;
    }
    .Gallery h1{
        font-size: 35px;
    }
    .Marketing h1{
        font-size: 35px;
    }
    .Team h1{
        font-size: 35px;
    }
    .Team p{
        font-size: 18px;
    }
    .Story p{
        font-size: 18px;
    }
    .Paper p{
        font-size: 18px;
    }
    .Mint p{
        font-size: 18px;
    }
    .RoadMap p{
        font-size: 18px;
    }
    .Reveal p{
        font-size: 18px;
    }
    .Club p{
        font-size: 18px;
    }
    .Gallery h2{
        font-size: 18px;
    }
    .Seen h1{
        font-size: 35px;
    }
    .Seen h2{
        font-size: 18px;
    }
    .Marketing p{
        font-size: 18px;
    }
    .Team p{
        font-size: 18px;
    }
}

@media only screen and (max-width: 650px) {
    .FootCal{
        display: none;
    }
    .NftArt{
        display: none;
    }
    .NftArt1{
        display: none;
    }
    .Seen{
        display: flex;
    }
}

@media only screen and (max-width: 500px) {
    .Story span{
        flex-direction: column;
    }
    .Story h1{
        text-align: center;
    }
    .StoryLeft{
        width: 100%;
        align-items: center;
    }
    .StoryRight{
        width: 100%;
        align-items: center;
        margin-top: 20px;
    }
    .Paper span{
        flex-direction: column;
    }
    .Paper h1{
        text-align: center;
    }
    .PaperLeft{
        width: 100%;
        align-items: center;
    }
    .PaperRight{
        width: 100%;
        align-items: center;
        margin-top: 20px;
    }
    .Mint span{
        flex-direction: column;
    }
    .MintLeft{
        width: 100%;
        align-items: center;
    }
    .MintRight{
        align-items: center;
        width: 100%;
        margin-top: 20px;
    }
    .RoadMapLeft{
        width: 100%;
    }
    .RoadMapRight{
        width: 100%;
        align-items: center;
        margin-top: 20px;
    }
    .TeamCon{
        display: none;
    }
    .Team h1{
        margin-bottom: 30px;
        text-align: center;
    }
    .TeamCon1{
        display: flex;
    }
    .Team span{
        flex-direction: column;
        margin-top: 0px;
    }
    .TeamLeft{
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .TeamRight{
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .TeamLeft1{
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .Team h2{
        text-align: center;
    }
    .TeamText{
        text-align: center;
    }
    .TeamRight1{
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .NavBoard{
        width: 50px;
    }
    .NavCC{
        width: 50px;
    }
    .NavCal{
        width: 70px;
    }
    .MenuIconCon{
        width: 100px;
    }
    .Logo{
        width: 100px;
    }
    .Story img{
        width: 100%;
    }
    .HeadMint{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Mint img{
        width: 100%;
    }
    .Paper img{
        width: 100%;
    }
    .RoadMap img{
        width: 100%;
    }
    .Team img{
        width: 100%;
    }
    .NavBarMenu1{
        width: 75%;
    }
}

@media only screen and (max-width: 400px) {
    .NavBarMenu1{
        width: 70%;
    }

    .NavCal{
        width: 60px;
    }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 25px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #ccc;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #ccc;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  